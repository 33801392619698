import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import Container from "../components/Container"
import H2 from "../components/H2"
import H3 from "../components/H3"
import P from "../components/P"
import Address from "../components/Footer/Address"
import theme from "../themes"
import { Grid } from "@material-ui/core"
import Table from "../components/Table"
import TableBody from "../components/TableBody"
import TableRow from "../components/TableRow"
import TableData from "../components/TableData"

const OeffnungszeitenPage = () => (
  <Layout>
    <SEO title="Öffnungszeiten" />
    <Section paddingTop={theme.main.sizes.paddings.bigger}>
      <Container>
        <H1>Öffnungszeiten</H1>
        <p>
          Die Kita Traumfänger öffnet ihre Tore um 6.30 Uhr und schliesst sie
          wieder um 19.00 Uhr.
        </p>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <H2>Adresse</H2>
            <Address>
              THE BUSINESS LOFT
              <br />
              Kita Traumfänger
              <br />
              Eisfeldstrasse 2a
              <br />
              6003 Luzern
              <br />
              Schweiz
              <br />
              <br />
            </Address>
          </Grid>
          <Grid item xs={12} sm={6}>
            <H2>Anfahrt mit dem ÖV</H2>
            <P margin={"0 0 24px 0"}>
              Die Kita Traumfänger liegt ca 5 Gehminuten von der Haltestelle
              "Eisfeld" entfernt.
            </P>

            <Table>
              <TableBody>
                <TableRow>
                  <TableData width={"50%"}>Haltestelle (ab)</TableData>
                  <TableData widthSecondColumn={"50%"}>Bus-Nr</TableData>
                </TableRow>
                <TableRow>
                  <TableData width={"70%"}>Luzern, Bahnhof</TableData>
                  <TableData widthSecondColumn={"30%"}>6, 7, 8, 21</TableData>
                </TableRow>
                <TableRow>
                  <TableData width={"70%"}>Luzern, Schönbühl</TableData>
                  <TableData widthSecondColumn={"30%"}>6, 7, 8, 21</TableData>
                </TableRow>
                <TableRow>
                  <TableData width={"70%"}>Horw, Biregghof</TableData>
                  <TableData widthSecondColumn={"30%"}>7</TableData>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    </Section>
  </Layout>
)

export default OeffnungszeitenPage
